import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs"
import ScrollAnimation from 'react-animate-on-scroll'
import { useStaticQuery, graphql } from "gatsby"
import { TableuIframe } from '../../../modules/tableu_iframe_graph_accordion';

import "./InventoryTrend.scss"
import { Item } from "semantic-ui-react"
// styles

// markup
const InventoryTrend = ( props ) => {


//console.log('InventryTrend', props.InventryTrend);

  return (

    <React.Fragment>
      <div className="inventory-trend-block">
         <div className="animated">
            <div className="property-area-title">
                <h2>Inventory Trend</h2>
            </div>

            {
            props.InventryTrend && (
                <div className="tableu_block">
                  <TableuIframe graphUrl={props.InventryTrend} />
                </div>
              )                
            }
         </div>
        </div>
    </React.Fragment>
  )
}

export default InventoryTrend