import * as React from "react"
import ScrollAnimation from 'react-animate-on-scroll';
import "./PropertyFacts.scss"
// styles

// markup
const PropertyFacts = ( props ) => {
  
  // console.log("propertyfacts", props.data);

  const ProjectFacts = props.data ? [
    {
      ProjectFactsName:"Location",
      ProjectFactsValues: props.data.Address && props.data.Address
    },{
      ProjectFactsName:"Developer",
      ProjectFactsValues: props.data.Developer && props.data.Developer
    },
    {
      ProjectFactsName:"Development Type",
      ProjectFactsValues: props.data.Development_Type && props.data.Development_Type
    },
    {
      ProjectFactsName:"Ownership",
      ProjectFactsValues: props.data.Address && props.data.Ownership
    },
    {
      ProjectFactsName:"Price (starting from):",
      ProjectFactsValues: props.data.Address && props.data.Price_Starting_From
    },
    {
      ProjectFactsName:"Land area (m²):",
      ProjectFactsValues: props.data.Address && props.data.Land_Area_Square_Meters
    },
    {
      ProjectFactsName:"Gross Floor Area (m²)",
      ProjectFactsValues: props.data.Address && props.data.Gross_Floor_Area_Square_Meters
    } 
  ] : []

  return (
  <React.Fragment>
   <div className="property-facts-section">
       <div className="animated">
         <div className="property-facts-title border-line">
           <h2>Project Facts</h2>
         </div>
         <div className="project-fact-list">
          <ul>
          {ProjectFacts.length > 0 && ProjectFacts.map((item,index)=> item.ProjectFactsValues && 
            <li className="" key={index}>
             <strong>{item.ProjectFactsName}</strong>
             <span>{item.ProjectFactsValues}</span>
           </li>
           )}
          </ul>

           </div>
         </div>
   </div>
  </React.Fragment>
  )
}

export default PropertyFacts