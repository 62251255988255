import React,{useState, useEffect} from "react"

var strapi_url = process.env.GATSBY_STRAPI_SRC;

export const TableuIframe = (props) => {

    return(
        <iframe marginheight="0" marginwidth="0" title="Data Visualization" scrolling="yes" allowtransparency="true" allowfullscreen="true" class="tableauViz" src={`${props.graphUrl}&:embed=y&:showVizHome=no&:host_url=https%3A%2F%2Fpublic.tableau.com%2F&:embed_code_version=3&:tabs=no&:toolbar=no&:animate_transition=no&:display_static_image=no&:display_spinner=no&:display_overlay=yes&:display_count=yes&:language=en-US&:loadOrderID=0`} frameborder="1"></iframe>
    )
}

