import React, { useState} from "react"
import { Link } from "@StarberryUtils";
import PropertyImg from '../../../images/map-area.jpg'
import Slider from "react-slick"

import FsLightbox from 'fslightbox-react';
import { ImageModule } from "../../../modules/Image_Module";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import "./PropertyArea.scss"
// styles

// markup
const PropertyArea = (props) => {

  // console.log("PropertyArea", props.data);
  const [toggler, setToggler] = useState(false);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLighboxMobile = (e, ind) => {
    e.preventDefault();
    setPhotoIndex(ind);
    setIsOpen(true);
  } 

  const PropertyAreaMap = props.data ? [
    {
      ProperyAreaImg: PropertyImg,
      ProperyAreaName: '2 Bedroom Apartment',
      ProperyAreaUnit: props.data.Land_Area_Square_Meters && props.data.Land_Area_Square_Meters
    },
    {
      ProperyAreaImg: PropertyImg,
      ProperyAreaName: '2 Bedroom Apartment',
      ProperyAreaUnit: props.data.Land_Area_Square_Meters && props.data.Land_Area_Square_Meters
    },
    {
      ProperyAreaImg: PropertyImg,
      ProperyAreaName: '2 Bedroom Apartment',
      ProperyAreaUnit: props.data.Land_Area_Square_Meters && props.data.Land_Area_Square_Meters
    },
    {
      ProperyAreaImg: PropertyImg,
      ProperyAreaName: '2 Bedroom Apartment',
      ProperyAreaUnit: props.data.Land_Area_Square_Meters && props.data.Land_Area_Square_Meters
    }

  ] : []

  const settings = {
    dots: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    mobileFirst: true,
    autoplay:true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          dots: false,
          autoplay:true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          autoplay:true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          autoplay:true,
          dots: true,
        },
      },
      {
        breakpoint: 99999,
        //settings: "unslick",
      },
    ],
  }

  //console.log("floor_plan", props.Floorplans);

  var lightImages2 = props.Floorplans && props.Floorplans.map(img => img.url);

  return (
    <React.Fragment>
      <div className="property-area-block" id="floorplans">
         <div className="animated">
          <div className="property-area-title">
            <h2>Floorplans</h2>
          </div>
          <div className="property-area-map">
            <Slider {...settings}>
              {props.Floorplans && props.Floorplans.map((item, index) => <div key={index}>
                <div className="property-area-img" onClick={(e) => openLighboxMobile(e, index)}>
                  <img src={item.url} alt="" />
                </div>
                {/* <div className="property-name">
                  <h3><Link href="#" className="">{item.ProperyAreaName}</Link></h3>
                  {item.ProperyAreaUnit && <span>From {item.ProperyAreaUnit} QR</span> }
                </div> */}
              </div>
              )}
            </Slider>
          </div>
         </div>
      </div>

      {/* <FsLightbox
              toggler={ toggler }
              sources= {
                PropertyAreaMap.map(lightboximg => {
                  const lightbox_image_url = lightboximg
                  // console.log("$$$$", lightbox_image_url.ProperyAreaImg)
                  return <>
                    <img src={lightbox_image_url.ProperyAreaImg} alt="" />
                  </>
                })
              }
            /> */}

        {isOpen && (
          <Lightbox
            mainSrc={lightImages2[photoIndex]}
            nextSrc={lightImages2[(photoIndex + 1) % lightImages2.length]}
            prevSrc={lightImages2[(photoIndex + lightImages2.length - 1) % lightImages2.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + lightImages2.length - 1) % lightImages2.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % lightImages2.length)
            }
          />
        )}

    </React.Fragment>
  )
}

export default PropertyArea