import React, { useState, useEffect, useRef, createRef } from "react" 
import axios from "axios"
import wordsToNumbers from 'words-to-numbers';
import "../components/NewProjectsDetail/NewProjectsDetail.scss"

import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import SEO from "../components/seo";
import NewProjectsDetail from "../components/NewProjectsDetail/NewProjectsDetail";
import { NewProjectDetails } from "../queries/common_use_query"
import Breadcrumbs_Insights from "../components/Breadcrumbs/Breadcrumbs_Insights"
import LoaderLogo from '../images/loader_logo_new.svg';
// styles

// markup
const NewProjectTemplate = (props) => {

  let path_name = (props.location.pathname).split('/');
  let get_url = path_name[path_name.length-1] !== "" ? path_name[path_name.length-1] : path_name[path_name.length-2];

  const { loading, error, data } = NewProjectDetails(get_url);

  const [testimonials, setTestimonials] = useState([]);
  const [renderComponent, setRenderComponent] = useState(false);
  const strapiconfig = {
    headers: {
      Authorization:
        `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }

  useEffect(() => {
    let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
    getitems(url);

    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  }, []);


  var ggl_reviews = [];
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig)// could be from env files
      if (data && data.length > 0) {
        data.map((item, index) => {
          //var star_no = wordsToNumbers(item.starRating);
              ggl_reviews.push(item);
        })
      }
      setTestimonials(ggl_reviews);
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }
 

  let projectPopularSearch ="";

  if(data?.newProjects[0]?.Select_Popular_Search) {
    projectPopularSearch = data?.newProjects[0]?.Select_Popular_Search;
  }else if(data?.newProjects[0]?.Region == "Dubai"){
    projectPopularSearch = "Popular_Search_New_Projects_In_Dubai";
  }else if(data?.newProjects[0]?.Region == "Qatar"){
    projectPopularSearch = "Popular_Search_New_Projects_In_Qatar";
  }

  var metaDescription= "";
  var Title = data && data?.newProjects[0]?.Meta_Title ? data?.newProjects[0]?.Meta_Title : data?.newProjects[0]?.Address
  let pStatus = data?.newProjects[0]?.Property_Status;
  let region = data?.newProjects[0]?.Region;
  if(data && data?.newProjects[0]?.Meta_Description){
    metaDescription = data?.newProjects[0]?.Meta_Description;
  }else if(pStatus === "Sale" || pStatus === "Rent" ||  region=== "Qatar" || region === "Dubai"){
     if(pStatus === "Sale" && region === "Dubai"){
      metaDescription = `Discover ${Title}. Explore new projects in Dubai at The Pearl Gates. Start your journey today!`;
     }else if(pStatus === "Rent" && region === "Qatar"){
      metaDescription = `Rent your dream home at ${Title}. Discover new projects for rent in Qatar at The Pearl Gates.`;
     }else if(pStatus === "Sale" && region === "Qatar"){
      metaDescription = `Discover ${Title}.  Find new projects for sale in Qatar at The Pearl Gates. Discover your dream home now!`;
  }
}
  

 //console.log(data?.newProjects[0])
  return (
    <Layout Layout={'Without_Banner'} classNames="Without_Banner detail-page" Select_Popular_Search={projectPopularSearch}>
      <SEO title={data && data?.newProjects[0]?.Meta_Title} description={metaDescription} />

      <Helmet />

      {/* <div className="container">
        <div className="row">
          <div className="video-detail-template">
            <Breadcrumbs_Insights page_name="New Projects" Heading={data && data.newProjects[0].Title} location={props.location} />
          </div>
        </div>
      </div> */}
      {
        (loading) ? (
          // <div className="main-banner no_lines">
          //     <div className="loader_div">
          //         {/* <img src={LoaderLogo} alt="Loader" title="Loader" /><br />Loading... */}
          //     </div>
          // </div>
          <section className={"loader-wrapper"}>
              <div id="loader-wrapper">
                  <div id="loader" class="new-loader">
                      <div className="new-loader-anime"></div>
                      <img className="logo-white loader-logo" src={LoaderLogo}  alt="logo"/>
                  </div>
                  <div class="loader-section section-left"></div>
                  <div class="loader-section section-right"></div>
              </div>
          </section>
        ) : <NewProjectsDetail property_data={data && data.newProjects[0]} location={props.location} description={metaDescription} testimonials={testimonials}/>
      }
      

    </Layout>
  )
}

export default NewProjectTemplate