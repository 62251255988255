import * as React from "react"
import { Link } from "@StarberryUtils";
import Slider from "react-slick"
import {Container, Row,Col} from 'react-bootstrap'
import ContactCard from "../../ContactCard1/ContactCard"
import PortfolioImgMobile from "../../../images/static/portfolio_img_mobile.jpg"
import PortfolioImgTablet from "../../../images/static/portfolio_img_tablet.jpg"

import PortfolioImgMobile1 from "../../../images/static/portfolio_img_mobile1.jpg"
import PortfolioImgTablet1 from "../../../images/static/portfolio_img_tablet1.jpg"

import PortfolioImgMobile2 from "../../../images/static/portfolio_img_mobile2.jpg"
import PortfolioImgTablet2 from "../../../images/static/portfolio_img_tablet2.jpg"
import ScrollAnimation from 'react-animate-on-scroll'
import "./AvailableUnit.scss"
// styles

// markup
const AvailableUnit = (props) => {
 
  const settings = {
    dots: false,
    speed: 1500,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    mobileFirst: true,
    autoplay:true,
    responsive: [
      {
        breakpoint:1199,
        settings: {
          speed:100,
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 99999,
        // settings: "unslick",
      },
    ],
  }

  //console.log("Available_Units", props.data.Available_Units.available_properties)

  return (

  <React.Fragment>
 <div className="available-unit"> 
   <Container>
     <Row>
       <Col lg={12}>
         <h2>Available Units in {props.Project_Name}</h2>
         <div className="available-unit-list">
            <Slider {...settings}>
              {
                props.data.Available_Units && props.data.Available_Units.available_properties.length > 0 && props.data.Available_Units.available_properties.map((item, index) => {

                  let uriStr = "";

                  if ( item.department === "residential") {
                    if( item.search_type === "sales" ) {
                      uriStr = `properties-for-sale/`
                    } else if ( item.search_type === "lettings" ) {
                      uriStr = `properties-for-rent/`
                    }
                  } else if ( item.department === "commercial") {
                    if( item.search_type === "sales" ) {
                      uriStr = `commercial-properties-for-sale/`
                    } else if ( item.search_type === "lettings" ) {
                      uriStr = `commercial-properties-for-rent/`
                    }
                  }

                  return(
                    <Link to={'/'+uriStr+item.slug+'-'+item.id}>
                       <div className="animated"> 
                        <ContactCard
                          //cardImgUnit={PortfolioImgMobile}
                          cardImg={item.images}
                          cardTitle={`${item.title}, ${item.display_address}`}
                          date={`${item.price.toLocaleString()} QR`}
                          cardTag={""}
                          cardId={item.id}
                        />
                       </div>
                    </Link>
                  )
                })
              }
            </Slider>
         </div>
       </Col>
       
     </Row>
  
    </Container>
 </div>
  </React.Fragment>
  )
}

export default AvailableUnit