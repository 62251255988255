import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs"
import ScrollAnimation from 'react-animate-on-scroll'

import "./PropertyHeading.scss"
import { Item } from "semantic-ui-react"
// styles

// markup
const PropertyHeading = ( props ) => {

  // console.log("data2", props.data);

  return (

    <React.Fragment>
       <div className="animated">
      {
        props.data &&
            <div className="heading-block">
             
                {/* <Breadcrumbs breadcrumb_data={props.location} breadcrumb_last={props.data.Name} /> */}


                <div className="heading-block-wrap">
                  <h1>{props.data.Name}</h1>
                  <div className="price-row">
                    {props.data.Price_Range && <span className="current-price">From {props.data.Price_Range}</span>}
                    {props.data.Price_Per_Sqft && <span className="old-price no_strike">({props.data.Price_Per_Sqft} per sqft) QR</span>}
                  </div>
                </div>
   
            </div>
      }
       </div>
    </React.Fragment>
  )
}

export default PropertyHeading